import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { prepareHeaders } from "../../auth/slice/AuthSlice";


const apiUrl = `/api`;
const customerUrl = `${apiUrl}/customer`;

export const fetchCategoryList = createAsyncThunk(
  'questionnaires/fetchCategoryList',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const headers = prepareHeaders(state);
      const response = await axiosInstance.get(`${apiUrl}/question-category/list`, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const fetchQuestionList = createAsyncThunk(
  'questionnaires/fetchQuestionList',
  async (code, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerUrl}/question/${code}`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const storeDraftAnswer = createAsyncThunk(
  'questionnaires/storeDraftAnswer',
  async ({ code, id }, { getState, rejectWithValue }) => {
    console.log(code);
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerUrl}/answer/store_draft_answer`, {
        params: { reference_code: code, question_category_id: id },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const saveQuestionnaireData = createAsyncThunk(
  'skus/saveQuestionnaireData',
  async (newQuestionnaire, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerUrl}/answer/store`, newQuestionnaire, {
        headers: prepareHeaders(state, true, {
          'Content-Type': 'multipart/form-data',
        })
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const changeRequestStatusToProgress = createAsyncThunk(
  'questionnaires/changeRequestStatusToProgress',
  async (code, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerUrl}/sku/in-progress/${code}`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

const QuestionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: {
    questionnaireStatus: null,
    questionnaire: null,
    categoryList: [],
    questionsList: [],
    loading: false,
    categoryLoading: false,
    error: null,
    success: null
  },
  reducers: {
    clearError: (state) => {
      state.error = '';
    },
    clearSuccess: (state) => {
      state.success = null;
    },
    setQuestionnaireStatus: (state, action) => {
      state.questionnaireStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch category list
      .addCase(fetchCategoryList.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchCategoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryList = action.payload.payload;
      })
      .addCase(fetchCategoryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch question list
      .addCase(fetchQuestionList.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchQuestionList.fulfilled, (state, action) => {
        state.loading = false;
        state.questionList = action.payload.payload.data;
      })
      .addCase(fetchQuestionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // store draft answer
      .addCase(storeDraftAnswer.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(storeDraftAnswer.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(storeDraftAnswer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // change Request Status To Progress
      .addCase(changeRequestStatusToProgress.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(changeRequestStatusToProgress.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changeRequestStatusToProgress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // save questionnaire data
      .addCase(saveQuestionnaireData.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(saveQuestionnaireData.fulfilled, (state, action) => {
        state.loading = false;
        state.questionnaire = action.payload.payload;
      })
      .addCase(saveQuestionnaireData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError, clearSuccess, setQuestionnaireStatus } = QuestionnaireSlice.actions;
export default QuestionnaireSlice.reducer;