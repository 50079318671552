import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { prepareHeaders } from "../../auth/slice/AuthSlice";
import { PER_PAGE_BILLING } from "../../../constants/pagination";


const customerSkuUrl = `/api/customer/sku`;

export const fetchBills = createAsyncThunk(
  'bills/fetchBills',
  async ({ page = 1, filter = '', perPage = PER_PAGE_BILLING }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerSkuUrl}/index`, {
        params: { page, per_page: perPage, search: filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

const BillingSlice = createSlice({
  name: "bill",
  initialState: {
    bills: [],
    bill: null,
    loading: false,
    pageCount: 0,
    currentPage: 0,
    filter: '',
    perPage: PER_PAGE_BILLING,
    error: null,
    success: null
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearSuccess: (state) => {
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch billings
      .addCase(fetchBills.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchBills.fulfilled, (state, action) => {
        state.loading = false;
        state.bills = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchBills.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilter, setPage, setPerPage, clearError, clearSuccess } = BillingSlice.actions;
export default BillingSlice.reducer;