import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { prepareHeaders } from "../../auth/slice/AuthSlice";


const apiUrl = `/api`;

export const saveContact = createAsyncThunk(
  'pages/saveContact',
  async (newContact, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${apiUrl}/contact-us/store`, newContact, {
        headers: prepareHeaders(state, false)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

const PageSlice = createSlice({
  name: "page",
  initialState: {
    contact: null,
    loading: false,
    error: null,
    success: null
  },
  reducers: {
    clearError: (state) => {
      state.error = '';
    },
    clearSuccess: (state) => {
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // save contact
      .addCase(saveContact.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(saveContact.fulfilled, (state, action) => {
        state.loading = false;
        state.contact = action.payload.payload;
      })
      .addCase(saveContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError, clearSuccess } = PageSlice.actions;
export default PageSlice.reducer;