import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { prepareHeaders } from "../../auth/slice/AuthSlice";
import { PER_PAGE_SKU } from "../../../constants/pagination";


const customerUrl = `/api/customer`;
const customerSkuUrl = `${customerUrl}/sku`;

export const fetchPriceList = createAsyncThunk(
  'priceLists/fetchPriceList',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const headers = prepareHeaders(state);
      const response = await axiosInstance.get('api/price-list', {
        headers: headers
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const fetchDiscountCoupon = createAsyncThunk(
  'coupons/fetchDiscountCoupon',
  async (coupon, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const headers = prepareHeaders(state);
      const response = await axiosInstance.get('api/customer/is-valid-discount-coupon', {
        headers: headers,
        params: coupon
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const saveCustomerSku = createAsyncThunk(
  'skus/saveCustomerSku',
  async (newCustomerSku, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerSkuUrl}/store`, newCustomerSku, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const fetchSkus = createAsyncThunk(
  'skus/fetchSkus',
  async ({ page = 1, filter = '', perPage = PER_PAGE_SKU, filter_status = '', sort_by = '', sort_direction = '' }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerSkuUrl}/listing_sku`, {
        params: { page, per_page: perPage, search: filter, filter_status, sort_by, sort_direction },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const createPaymentIntent = createAsyncThunk(
  'skus/createPaymentIntent',
  async (newData, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${customerUrl}/create-payment-intent`, newData, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const fetchSkuCount = createAsyncThunk(
  'skus/fetchSkuCount',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerSkuUrl}/count`, {
        headers: prepareHeaders(state, true)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

const SkuSlice = createSlice({
  name: "sku",
  initialState: {
    skus: [],
    sku: null,
    skuCountData: null,
    customerSku: null,
    discount: null,
    paymentIntent: null,
    priceList: [],
    loading: false,
    priceListLoading: false,
    pageCount: 0,
    currentPage: 0,
    filter: '',
    filter_status: '',
    sort_by: '',
    sort_direction: '',
    perPage: PER_PAGE_SKU,
    error: null,
    success: null
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setFilterStatus: (state, action) => {
      state.filter_status = action.payload;
      state.currentPage = 0;
    },
    setSortData: (state, action) => {
      state.sort_by = action.payload?.key;
      state.sort_direction = action.payload?.order;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    setCustomerSku: (state, action) => {
      state.customerSku = action.payload;
    },
    clearCustomerSku: (state) => {
      state.customerSku = null;
    },
    clearDiscount: (state) => {
      state.discount = null;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearPaymentIntent: (state) => {
      state.paymentIntent = null;
    },
    clearSku: (state) => {
      state.sku = null;
    },
    clearSuccess: (state) => {
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch skus
      .addCase(fetchSkus.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchSkus.fulfilled, (state, action) => {
        state.loading = false;
        state.skus = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchSkus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch pricelist
      .addCase(fetchPriceList.pending, (state) => {
        state.priceListLoading = true;
        state.error = '';
      })
      .addCase(fetchPriceList.fulfilled, (state, action) => {
        state.priceListLoading = false;
        state.priceList = action.payload.payload;
      })
      .addCase(fetchPriceList.rejected, (state, action) => {
        state.priceListLoading = false;
        state.error = action.payload;
      })
      // fetch discount coupon
      .addCase(fetchDiscountCoupon.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchDiscountCoupon.fulfilled, (state, action) => {
        state.loading = false;
        state.discount = action.payload.payload;
      })
      .addCase(fetchDiscountCoupon.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch sku count
      .addCase(fetchSkuCount.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchSkuCount.fulfilled, (state, action) => {
        state.loading = false;
        state.skuCountData = action.payload.payload;
      })
      .addCase(fetchSkuCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // create payment intent
      .addCase(createPaymentIntent.pending, (state, action) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentIntent = action.payload.payload;
        state.error = '';
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // save customer sku
      .addCase(saveCustomerSku.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(saveCustomerSku.fulfilled, (state, action) => {
        state.loading = false;
        state.sku = action.payload.payload;
      })
      .addCase(saveCustomerSku.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setCustomerSku, clearPaymentIntent, clearSku, setFilter, setFilterStatus, setSortData, setPage, setPerPage, clearCustomerSku, clearDiscount, clearError, clearSuccess } = SkuSlice.actions;
export default SkuSlice.reducer;